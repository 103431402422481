import styled from 'styled-components';
import PropTypes from 'prop-types';

import style from '@constants/style';

const StyledFlippableFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    margin: ${({ verticalSpace }) => style.margin[verticalSpace]} 0;
  }
  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  ${({ breakSize }) => style.media[breakSize]`
    flex-direction: row;
    width: 100%;
    & > * {
      margin: 0 ${({ horizontalSpace }) => style.margin[horizontalSpace]};
    }
    & > *:first-child {
      margin-left: 0;
    }
    & > *:last-child {
      margin-right: 0;
    }
  `}
`;

StyledFlippableFlexBox.propTypes = {
  breakSize: PropTypes.string,
  verticalSpace: PropTypes.string,
  horizontalSpace: PropTypes.string,
  children: PropTypes.node.isRequired,
};
StyledFlippableFlexBox.defaultProps = {
  breakSize: 'laptop',
  verticalSpace: 'regular',
  horizontalSpace: 'regular',
};

const FlippableFlexBox = StyledFlippableFlexBox;

export default FlippableFlexBox;
