import React, { useCallback, useContext } from 'react';
import Proptypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import info from '@constants/info';

import { LiveAgentContext } from '@components/containers/LiveAgent';

import Button from '@components/elements/Button';
import Anchor from '@components/elements/Anchor';

const ContactUsButton = ({ isBlack, isLink, underlined }) => {
  const liveAgentButton = useContext(LiveAgentContext);
  const startChat = useCallback((e) => {
    e.preventDefault();
    try {
      liveAgentButton.onClick();
    } catch (error) {
      Sentry.captureException(error);
      window.location = `mailto:${info.CUSTOMER_SERVICE_EMAIL}`;
    }
  }, [liveAgentButton]);
  return isLink ? (
    <Anchor onClick={startChat} underlined={underlined}>Starta chatt</Anchor>
  ) : (
    <Button
      onClick={startChat}
      size="big"
      type={isBlack ? 'primary' : 'negativePrimary'}
    >
      Starta chatt
    </Button>
  );
};

ContactUsButton.propTypes = {
  isBlack: Proptypes.bool,
  isLink: Proptypes.bool,
  underlined: Proptypes.bool,
};

ContactUsButton.defaultProps = {
  isBlack: false,
  isLink: false,
  underlined: false,
};

export default ContactUsButton;
