import React from 'react';
import Proptypes from 'prop-types';

import style from '@constants/style';

import Section from '@components/containers/Section';
import StretchedCard from '@components/containers/StretchedCard';
import FlippableFlexBox from '@components/containers/FlippableFlexBox';

import Paragraph from '@components/elements/Paragraph';
import Headline from '@components/elements/Headline';
import ContactUsButton from '@components/elements/ContactUsButton';

const ContactUs = ({ noOverlap, isBlack }) => (
  <Section
    color={isBlack ? style.colours.secondaryLightGray1 : style.colours.secondaryLightGray3}
    fullWidth
    overlapSpace={noOverlap ? null : 'hugeer'}
    overlapColour={noOverlap ? null : style.colours.primaryLight}
  >
    <Headline as="h2" fontSize="big" textAlign="center">Vi finns här för att hjälpa dig</Headline>
    <StretchedCard isBlack={isBlack}>
      <FlippableFlexBox
        verticalSpace="medium"
        horizontalSpace="regular"
      >
        <Paragraph colour={isBlack ? 'secondaryLightGray1' : null} fontSize="huge" textAlign="center">Hej! Prata direkt med oss</Paragraph>
        <ContactUsButton isBlack={!isBlack} />
      </FlippableFlexBox>
    </StretchedCard>
  </Section>
);

ContactUs.propTypes = {
  noOverlap: Proptypes.bool,
  isBlack: Proptypes.bool,
};

ContactUs.defaultProps = {
  noOverlap: false,
  isBlack: false,
};

export default ContactUs;
