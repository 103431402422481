import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import WrappableGridStyled, { WrappableGridItemStyled, layouts } from '@components/containers/WrappableGridStyled';

const getLayout = (currentWidth, minWidthItem, gapSize, nbItems) => {
  if (currentWidth / 16 > nbItems * minWidthItem + (nbItems - 1) * gapSize) {
    return layouts.ROW;
  }
  if (currentWidth / 16 > 2 * minWidthItem + gapSize) {
    return layouts.TWO_BY_2;
  }
  return layouts.COLUMN;
};

const WrappableGrid = ({
  children,
  minWidthItem,
  gap,
}) => {
  const containerRef = useRef();

  const [layout, setLayout] = useState(layouts.COLUMN);

  const numberOfItems = React.Children.count(children);
  if (numberOfItems % 2 !== 0) throw new Error('Received an odd number of children. WrappableGrid can only support an even number of children');

  const roundedGap = Math.round(gap * 2) / 2;
  const roundedMinWidthItem = Math.round(minWidthItem * 2) / 2;

  useEffect(() => {
    const handleResize = () => {
      setLayout(getLayout(
        containerRef.current.clientWidth,
        roundedMinWidthItem,
        roundedGap,
        numberOfItems,
      ));
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [roundedMinWidthItem, roundedGap, numberOfItems]);

  return (
    <WrappableGridStyled ref={containerRef}>
      {React.Children.map(children, (child) => (
        <WrappableGridItemStyled
          gap={roundedGap}
          numberOfItems={numberOfItems}
          layout={layout}
        >
          {child}
        </WrappableGridItemStyled>
      ))}
    </WrappableGridStyled>
  );
};

WrappableGrid.propTypes = {
  gap: PropTypes.number.isRequired,
  minWidthItem: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default WrappableGrid;
