import styled from 'styled-components';

import style from '@constants/style';

const StyledStretchedCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ isBlack }) => (isBlack ? style.colours.secondaryDarkGray1 : style.colours.secondaryLightGray1)};
  padding: ${style.padding.gigantic} ${style.padding.huge} ${style.padding.huge};

  ${style.media.tablet`
    padding: ${style.padding.godlike} ${style.padding.gigantic} ${style.padding.hugeer};
  `}
  ${style.media.laptop`
    padding: ${style.padding.huge} 0 ${style.padding.huge};
    width: 100%;
  `}
`;

const StretchedCard = StyledStretchedCard;

export default StretchedCard;
