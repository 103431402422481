import styled, { css } from 'styled-components';

export const layouts = {
  ROW: 'ROW',
  COLUMN: 'COLUMN',
  TWO_BY_2: '2_BY_2',
};

const getBehaviour = ({ layout, gap, numberOfItems }) => {
  if (layout === layouts.COLUMN) {
    return css`
      width: 100%;
      justify-content: flex-start;
      margin: ${gap / 2}rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    `;
  }
  if (layout === layouts.TWO_BY_2) {
    return css`
      width: calc((100% - ${gap}rem) / 2);
      margin: ${gap / 2}rem;

      &:nth-child(odd) {
        margin-left: 0;
        justify-content: flex-start;
      }

      &:nth-child(even) {
        margin-right: 0;
        justify-content: flex-end;
      }

      &:nth-child(-n+${numberOfItems / 2}) {
        margin-top: 0;
      }

      &:nth-child(n+${numberOfItems / 2 + 1}) {
        margin-bottom: 0;
      }
    `;
  }

  return css`
    width: calc((100% - ${(numberOfItems - 1) * gap}rem) / ${numberOfItems});

    &:nth-child(n) {
      margin: 0 ${gap / 2}rem;
      justify-content: center;
    }

    &:first-child {
      margin-left: 0;
      justify-content: flex-start;
    }

    &:last-child {
      margin-right: 0;
      justify-content: flex-end;
    }
  `;
};

export const WrappableGridItemStyled = styled('div')`
  display: flex;
  flex-direction: row;

  ${(props) => getBehaviour(props)}
`;

const WrappableGridStyled = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export default WrappableGridStyled;
