import React from 'react';
import { navigate } from 'gatsby';

import { urls } from '@constants';
import getUrl from '@utils/getUrl';

import style from '@constants/style';

import Page from '@components/containers/Page';
import ContactUs from '@components/modules/ContactUs';
import Button from '@components/elements/Button';

import Paragraph from '@components/elements/Paragraph';
import Headline from '@components/elements/Headline';

import Section from '@components/containers/Section';
import WrappableGrid from '@components/containers/WrappableGrid';

const goBack = (e) => {
  e.preventDefault();
  navigate(-1);
};

const PageNotFound = () => (
  <Page>
    <Section narrow color={style.colours.secondaryLightGray1}>
      <Headline as="h2" fontSize="big" color="primaryDark">Sidan hittades inte</Headline>
      <Paragraph marginBottom="huge">Sidan du letar efter finns inte eller har tagits bort. Med länkarna nedan kan du gå till vår startsida eller gå tillbaka.</Paragraph>
      <WrappableGrid minWidthItem={10} gap={1}>
        <Button to={getUrl(urls.LANDING)}>Startsida</Button>
        <Button onClick={goBack}>Gå tillbaka</Button>
      </WrappableGrid>
    </Section>
    <ContactUs noOverlap />
  </Page>
);

export default PageNotFound;
